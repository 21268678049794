import React from "react";
import {
  useLoadScript,
  GoogleMap,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import GatsbyImage from "gatsby-image";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import Icon from "../components/Icon";
import { GOOGLE_MAPS_SCRIPT_ID, FARM_ROUTE } from "../constants";
import { GOOGLE_MAPS_API_KEY } from "../config";
import FarmInfoTable from "../components/FarmInfoTable";

const defaultMapColor = "#2e7d32";
const activeMapColor = "#0097a7";

const libraries = ["places"];
export default ({
  data: {
    allFarm: { edges: farms },
  },
}) => {
  const initMetaArray = farms.map(() => false);
  const mapRef = React.useRef();
  const [farmMapMeta, setFarmMapMeta] = React.useState(initMetaArray);

  function toggleMapItemActive(index) {
    const meta = farmMapMeta.map((item, i) => {
      if (i === index) {
        return !item;
      }
      return false;
    });
    setFarmMapMeta(meta);
  }
  function handleMapButtonClick(index) {
    if (!farmMapMeta[index]) {
      mapRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
    toggleMapItemActive(index);
  }
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries,
    id: GOOGLE_MAPS_SCRIPT_ID,
  });
  const markers = farms.map(({ node: { lat, lng } }, index) => {
    return (
      <Marker
        key={`marker-${index}`}
        position={{ lat, lng }}
        icon={{
          path:
            "M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z",
          fillColor: farmMapMeta[index] ? activeMapColor : defaultMapColor,
          fillOpacity: 0.8,
          scale: 0.1,
          strokeColor: farmMapMeta[index] ? activeMapColor : defaultMapColor,
          strokeWeight: 1,
          labelOrigin: { x: 190, y: 200 },
          anchor: { x: 190, y: 0 },
        }}
        label={{
          color: "#ffffff",
          fontSize: "22px",
          text: `${index + 1}`,
        }}
        onClick={() => toggleMapItemActive(index)}
        zIndex={farmMapMeta[index] ? 9999 : undefined}
      />
    );
  });
  const infoWindows = farms.map(
    (
      {
        node: {
          name,
          lat,
          lng,
          bannerImage,
          profileImage,
          about,
          path,
          location,
        },
      },
      index
    ) => {
      const itemActive = farmMapMeta[index];
      if (itemActive) {
        return (
          <InfoWindow
            key={`info-window-${index}`}
            position={{ lat, lng }}
            onCloseClick={() => toggleMapItemActive(index)}
            options={{ maxWidth: 350, minWidth: 280 }}
          >
            <div className="info-window-container">
              <div className="banner-img">
                <GatsbyImage
                  fluid={bannerImage.childImageSharp.fluid}
                  style={{ height: "100%" }}
                />
              </div>
              <div className="profile-img">
                <GatsbyImage fluid={profileImage.childImageSharp.fluid} />
              </div>
              <h5>{name}</h5>
              <p className="location">{location}</p>
              <div dangerouslySetInnerHTML={{ __html: about }} />
              <p>
                <Link
                  to={`${FARM_ROUTE}${path}`}
                  className="btn primary-outline-btn small-btn"
                >
                  VIEW FARM
                </Link>
              </p>
            </div>
          </InfoWindow>
        );
      }
      return <React.Fragment key={`fragment-${index}`}></React.Fragment>;
    }
  );
  const handleMapLoad = (map) => {
    let bounds = new window.google.maps.LatLngBounds();
    farms.forEach(({ node: { lat, lng } }) => {
      bounds.extend(new window.google.maps.LatLng({ lat, lng }));
    });
    map.fitBounds(bounds);
  };
  return (
    <Layout pageTitle="Food sources" ogUrl={FARM_ROUTE}>
      <div className="grid-container">
        <div className="grid-item-2 farm-page-container">
          <div className="green-heading">
            <h4>SEE WHERE YOUR FOOD COMES FROM</h4>
          </div>
          <div className="add-padding-2">
            {farms.map(
              (
                {
                  node: {
                    name,
                    profileImage,
                    bannerImage,
                    location,
                    path,
                    productCategories,
                    organicStatus,
                    availableProducts,
                  },
                },
                index
              ) => {
                const itemActive = farmMapMeta[index];
                return (
                  <div
                    key={`farm-item-${index}`}
                    className="paper farm-list-item"
                  >
                    <div className="index-icon">
                      <Icon iconName="mapMarkerSolid" width={34} />
                      <span>{index + 1}</span>
                    </div>
                    <div className="farm-list-banner">
                      <GatsbyImage
                        fluid={bannerImage.childImageSharp.fluid}
                        style={{ height: "100%" }}
                      />
                    </div>
                    <div className="img-item">
                      <Link
                        className="img-container"
                        to={`${FARM_ROUTE}${path}`}
                      >
                        <GatsbyImage
                          fluid={profileImage.childImageSharp.fluid}
                        />
                      </Link>
                    </div>
                    <div className="content-container">
                      <h4>{name}</h4>
                      <div className="margin-bottom-1">
                        <FarmInfoTable
                          location={location}
                          availableProducts={availableProducts}
                          organicStatus={organicStatus}
                        />
                      </div>
                      <div>
                        {productCategories.map((item) => (
                          <div key={item} className="category-chip">
                            <span>{item.toUpperCase()}</span>
                          </div>
                        ))}
                      </div>
                      <div className="grid-container add-width-1">
                        <div className="add-padding">
                          <button
                            onClick={() => handleMapButtonClick(index)}
                            className={`btn small-btn full-width ${
                              itemActive ? "secondary-btn" : "white-btn"
                            }`}
                            style={{}}
                          >
                            {itemActive ? "SHOWING" : "SHOW ON MAP"}
                          </button>
                        </div>
                        <div className="add-padding">
                          <Link
                            to={`${FARM_ROUTE}${path}`}
                            className="btn primary-btn small-btn full-width"
                          >
                            VIEW FARM
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
        <div
          className="grid-item-2 farm-page-container farm-google-map"
          ref={mapRef}
        >
          {isLoaded && (
            <GoogleMap
              onLoad={handleMapLoad}
              mapContainerStyle={{
                height: "100%",
                width: "100%",
              }}
              zoom={11}
              options={{
                mapTypeControl: false,
                fullscreenControl: false,
              }}
            >
              {markers}
              {infoWindows}
            </GoogleMap>
          )}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query FarmQuery {
    allFarm(
      sort: { fields: name }
      filter: { status: { eq: "ACTIVE" }, name: { ne: "till" } }
    ) {
      edges {
        node {
          id
          about
          lat
          lng
          location
          name
          path
          productCategories
          availableProducts
          organicStatus
          profileImage {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          bannerImage {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
